import { datadogLogs } from "@datadog/browser-logs";
import { TRACK_EVENTS } from "@recare/core/consts";
import auth from "@recare/core/model/api/endpoints/auth";
import { clearEncryptionCaches } from "apollo/encryption/caches";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTracking } from "react-tracking";

export function useOnLogout() {
  const { trackEvent } = useTracking();
  const dispatch = useDispatch();

  return useCallback(
    (options?: { hardRefresh?: boolean; tokenExpired?: boolean }) => {
      if (!options?.tokenExpired) auth.logout();
      trackEvent({
        name: TRACK_EVENTS.LOGGED_OUT,
        type: "connect",
        token_expired: options?.tokenExpired,
        forced: options?.hardRefresh,
      });

      datadogLogs.clearUser();

      clearEncryptionCaches();
      dispatch({
        type: "LOGGED_OUT",
      });
      if (options?.hardRefresh) {
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.reload();
      }
    },
    [],
  );
}

export function useLanguageChange() {
  const dispatch = useDispatch();

  return useCallback(
    (language: string) =>
      dispatch({
        type: "LANGUAGE_CHANGED",
        payload: {
          language,
        },
      }),
    [],
  );
}
