import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FormHelperTextProps, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { InputWidthType } from "@recare/core/types";
import {
  FormElement,
  FormElementProps,
  isValid,
} from "@recare/react-forms-state";
import { useTranslations } from "@recare/translations";
import Cleave from "cleave.js";
import { LockSecurePin } from "ds/components/Icons/SecurePin";
import Tooltip from "ds/components/Tooltip";
import { ICON_GREY } from "ds/materials/colors";
import { HorizontalLayout } from "ds/materials/layouts";
import { dp, inputWidth, margin } from "ds/materials/metrics";
import {
  FONT_SIZE_14,
  FONT_SIZE_16,
  FONT_SIZE_18,
} from "ds/materials/typography";
import { useBrowserContext } from "dsl/atoms/BrowserProvider";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { CSSProperties, ReactNode, useEffect, useMemo, useState } from "react";
import { useLocale } from "reduxentities/selectors";
import { getDelimiter } from "test/utils/data/dates";
import { getHelperText } from "../Validation";

export type ConnectedTextInputProps = Omit<FormElementProps, "onChange"> &
  Omit<TextInputFieldProps, "onChange"> &
  Required<Pick<TextInputFieldProps, "elementName">>;

export type ConnectedTextInputWithTooltip = ConnectedTextInputProps & {
  tooltip?: string;
};

export type MaskType = "date" | "iban" | "time";

export type TextInputFieldProps = Pick<
  TextFieldProps,
  | "autoComplete"
  | "autoFocus"
  | "disabled"
  | "fullWidth"
  | "id"
  | "inputProps"
  | "inputRef"
  | "label"
  | "maxRows"
  | "multiline"
  | "onKeyDown"
  | "placeholder"
  | "required"
  | "value"
> & {
  ariaDescribedBy?: string;
  elementName?: string;
  endAdornment?: ReactNode;
  hasCustomValidation?: boolean;
  hide?: boolean;
  inputType?: InputWidthType;
  marginOverride?: string;
  mask?: MaskType;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  onClick?: () => void;
  secure?: string;
  startAdornment?: ReactNode;
  style?: TextFieldProps["sx"];
  testId?: string;
  textInputType?: TextFieldProps["type"];
  unit?: string;
  validateOverride?: string;
  validation?: any;
  variant?: "filled" | "outlined" | "standard";
  width?: CSSProperties["width"];
};

// export for tests
export const TextInputField = ({
  ariaDescribedBy,
  elementName,
  label,
  placeholder = "",
  value,
  onChange = () => {},
  onKeyDown = () => {},
  width,
  validation,
  disabled,
  onClick,
  style,
  required,
  inputType,
  fullWidth = false,
  multiline = false,
  maxRows,
  hide,
  marginOverride,
  textInputType,
  inputProps = {},
  validateOverride,
  autoFocus,
  testId,
  autoComplete,
  inputRef,
  unit,
  hasCustomValidation = false,
  startAdornment,
  endAdornment,
  secure,
  mask,
  id,
  onBlur,
  variant,
}: TextInputFieldProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const translations = useTranslations();
  const { isChrome } = useBrowserContext();
  const { isMobile } = useMedia();
  const locale = useLocale();
  const fieldId = `input_text_${id ? id : elementName || ""}`;

  useEffect(() => {
    let cleave: Cleave;
    switch (mask) {
      case "date": {
        const delimiter = getDelimiter(locale);

        cleave = new Cleave(`#${fieldId}`, {
          date: true,
          delimiter,
          datePattern: ["d", "m", "Y"],
          onValueChanged: (e) => onChange(e.target.value),
        });
        break;
      }
      case "time": {
        cleave = new Cleave(`#${fieldId}`, {
          time: true,
          delimiter: ":",
          timePattern: ["h", "m"],
          onValueChanged: (e) => onChange(e.target.value),
        });
        break;
      }
      case "iban":
        {
          cleave = new Cleave(`#${fieldId}`, {
            delimiter: " ",
            blocks: [4, 4, 4, 4, 4, 2],
            onValueChanged: (e) => onChange(e.target.value),
          });
        }
        break;
      default:
        break;
    }

    return () => {
      if (cleave != null) cleave.destroy();
    };
  }, [fieldId, mask, locale]);

  const endAdornmentElement = useMemo(() => {
    let elem;
    const adornmentIconStyle = { fontSize: FONT_SIZE_18 };
    const endAdornmentSxProps = {
      height: "100%",
      paddingBottom: dp(5),
    };

    if (textInputType === "password")
      elem = (
        <IconButton
          size="large"
          onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          aria-label={
            isPasswordVisible
              ? translations.login.passwordVisibility.hide
              : translations.login.passwordVisibility.show
          }
        >
          {isPasswordVisible ? (
            <VisibilityOff style={adornmentIconStyle} htmlColor={ICON_GREY} />
          ) : (
            <Visibility style={adornmentIconStyle} htmlColor={ICON_GREY} />
          )}
        </IconButton>
      );

    if (secure)
      elem = <LockSecurePin style={adornmentIconStyle} tooltip={secure} />;

    return (
      <HorizontalLayout
        data-testid={`${elementName}_adornment`}
        aligned
        overflow="visible"
      >
        {unit ? (
          <InputAdornment position="end" sx={endAdornmentSxProps}>
            <Typography style={{ fontSize: FONT_SIZE_14 }}>{unit}</Typography>
          </InputAdornment>
        ) : null}
        {endAdornment ? (
          <InputAdornment position="end" sx={endAdornmentSxProps}>
            {endAdornment}
          </InputAdornment>
        ) : null}
        {elem ? (
          <InputAdornment position="end" sx={endAdornmentSxProps}>
            {elem}
          </InputAdornment>
        ) : null}
      </HorizontalLayout>
    );
  }, [textInputType, secure, isPasswordVisible, unit, endAdornment]);

  if (hide) return null;
  const type = (function () {
    if (textInputType !== "password") return textInputType;
    return isPasswordVisible ? "text" : "password";
  })();

  return (
    <TextField
      autoComplete={
        autoComplete != null
          ? autoComplete
          : isChrome
          ? `new-${elementName || "input"}`
          : "off"
      }
      autoFocus={autoFocus}
      disabled={disabled}
      error={!isValid(validation)}
      FormHelperTextProps={{
        ...({
          "data-testid": `${elementName}-form-helper-text`,
        } as FormHelperTextProps),
      }}
      fullWidth={fullWidth}
      id={fieldId}
      helperText={getHelperText({
        hasCustomValidation,
        translations,
        validateOverride,
        validation,
      })}
      InputProps={{
        endAdornment: endAdornmentElement,
        startAdornment: startAdornment ? (
          <InputAdornment position="start">
            <Typography
              style={{ fontSize: FONT_SIZE_14, paddingBottom: dp(5) }}
            >
              {startAdornment}
            </Typography>
          </InputAdornment>
        ) : null,
      }}
      InputLabelProps={{
        style: { fontSize: isMobile ? FONT_SIZE_16 : FONT_SIZE_14 },
      }}
      inputProps={{
        ...inputProps,
        style: { fontSize: isMobile ? FONT_SIZE_16 : FONT_SIZE_14 },
        ...{ "data-testid": testId || elementName },
      }}
      inputRef={inputRef}
      label={label}
      required={required}
      maxRows={maxRows}
      multiline={multiline}
      name={elementName}
      onChange={(e) => onChange(e.target.value)}
      onClick={onClick}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      size="small"
      sx={{
        margin: marginOverride || margin(1, 2),
        width: width || inputWidth(inputType),
        fontSize: FONT_SIZE_14,
        ...style,
      }}
      type={type}
      value={value === null ? "" : value}
      variant={variant ?? "standard"}
      aria-describedby={ariaDescribedBy}
    />
  );
};

const ConnectedTextInputField =
  FormElement()<ConnectedTextInputProps>(TextInputField);

export default function ConnectedTextInputFieldWithTooltip(
  props: ConnectedTextInputWithTooltip,
) {
  return (
    <Tooltip title={props.tooltip}>
      <ConnectedTextInputField {...props} />
    </Tooltip>
  );
}
